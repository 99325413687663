import React from 'react';
import _ from 'lodash';

import PropTypes from 'prop-types';
import Layout from '../components/layout';
import SectionContainer from '../components/SectionContainer';
import FAQDudes from '../images/faq/FAQdudes.png';
import { faqData } from '../pagePartials/faq';
import Squiggles from '../pagePartials/faq/Squiggles';

const Faq = props => (
  <Layout location={props.location}>
    <Squiggles>
      <div className="faq-page">
        <SectionContainer num={1}>
          <img src={FAQDudes} alt="faq" />
          <h2>Frequently Asked Questions</h2>
        </SectionContainer>
        <SectionContainer container num={2}>
          {_.map(faqData, ({ id, question, answer }) => (
            <div key={id} className="faq">
              <p className="question">{question}</p>
              <div className="answer">{answer}</div>
            </div>
          ))}
        </SectionContainer>
      </div>
    </Squiggles>
  </Layout>
);

Faq.propTypes = {
  location: PropTypes.object.isRequired,
};

export default Faq;
